<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout v-bind:activeNum="active" v-on:updateActiveNum="updateNum"></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>VPN</h2>
                    <p>국내 최다 레퍼런스를 가지고 안정성과 보안성을 보장하는 고품질 가상 사설망 서비스</p>
                </div>
                <div class="location">
                    <ul>
                        <li><a href="#">PRODUCTS</a></li>
                        <li><a href="#">VPN</a></li>
                        <li><span>서비스소개</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="sub-section svcInfo-vpn bgGray">
            <dl class="innWrap flex sLayout01">
                <dt>
                    <h3 class="sLtitle">서비스 소개</h3>
                    <p>
                        본지사간 보안망, 재택근무 등 원격보안접속이 필요한<br />
                        기업/공공고객을 대상으로, 보안과 품질이 보장된 전용 네트워크를<br />
                        제공합니다.
                    </p>
                    <a @click="$router.push('/goSupCns000')" class="btn-md arrow btn-primary bdrds50 mgr30">컨설팅 신청</a>
                </dt>
                <dd>
                    <div class="imgWrap">
                        <div class="img"></div>
                    </div>
                </dd>
            </dl>
        </section>

        <section class="sub-section DetailInfo">
            <div class="innWrap scrWrap flex">
                <div class="scrNav">
                    <ul>
                        <li>
                            <span class="line" v-bind:class="{active: active === 1}"></span><a href="" @click="moveTab(1)" v-smooth-scroll v-bind:class="{active: active === 1}">상세정보</a>
                        </li>
                        <li>
                            <span class="line" v-bind:class="{active: active === 2}"></span><a href="" @click="moveTab(2)" v-smooth-scroll v-bind:class="{active: active === 2}">요금안내</a>
                        </li>
                        <li>
                            <span class="line" v-bind:class="{active: active === 3}"></span><a href="" @click="moveTab(3)" v-smooth-scroll v-bind:class="{active: active === 3}">이용안내</a>
                        </li>
                    </ul>
                </div>

                <div class="scrCon">
                    <div id="section-01" class="vpn-section-01 Sbdb1px">
                        <h3 class="sCtitle">상세정보</h3>
                        <div class="con01">
                            <h4>1. 기본서비스</h4>

                            <table class="tbl_type03 tbl_Gray">
                                <colgroup>
                                    <col style="width: 19%">
                                    <col style="">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>내용</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Premium VPN<br />[속도 기반]</td>
                                        <td class="agl">
                                            메트로이더넷을 통해 MPLS 보안을 제공하는 프리미엄 서비스입니다.<br />
                                            공중 인터넷망과 분리된 VPN 전용망에 보안기술(MPLS)을 적용하여 기업 사설망을 구축/제공 받으실 수 있습니다.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>X4Biz Plus<br />[유저 수 기반]</td>
                                        <td class="agl">
                                            메트로이더넷 기반에 보안장비(방화벽, IPS기능 등)를 활용한 통합보안 서비스입니다.<br />
                                            Bandwidth 기반 서비스에서 벗어나 유저 중심적이고 고객 환경에 최적화된 네트워크를 제공 받으실 수 있습니다.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>X4Biz<br />[유저 수 기반]</td>
                                        <td class="agl">
                                            초고속인터넷 회선 기반에 보안장비(방화벽, IPS기능 등)를 활용한 통합보안 서비스입니다.<br />
                                            MPLS-VPN망과 초고속인터넷(ADSL)을 연동하여 안정적으로 기업의 유선 Internet/Intranet에 접속합니다.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>SSL VPN<br />[ID 기반]</td>
                                        <td class="agl">
                                            사외에서 재택/원격 근무자가 사내 네트워크로 보안접속을 가능하게 하는 서비스입니다.<br />
                                            고객 컴퓨터에 별도의 Client 프로그램 없이 웹 브라우저 기반으로 가상 사설망에 원격 접속합니다.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mgt20">
                                ※ MPLS-VPN (Multi Protocol Label Switching-Virtual Private Network) : MPLS 기술을 이용한 가상 사설망 입니다.<br />
                                <span class="pdl20">MPLS-VPN은 하나의 물리적 회선에서 고객별로 완벽한 트래픽 분리하여 높은 신뢰성과 보안수준을 보장할 수 있습니다.</span><br />
                                ※ IPS (Intrusion Prevention System) : 바이러스웜이나 불법침입, DDOS등의 비정상적인 이상 신호 발생 시 인공지능적으로 적절한 조치를 취하는 보안 시스템 입니다.<br />
                                ※ QoS (Quaility of Service) : 통신 서비스 품질, 네트워크 상에서 일정 정도 이하의 데이터 지연 시간이나 데이터 손실률 보장을 뜻합니다.
                            </p>
                        </div>
                    </div>

                    <div id="section-02" class="vpn-section-02 Sbdb1px">
                        <h3 class="sCtitle">요금안내</h3>

                        <div class="con01">
                            <h4>1. Premium VPN</h4>
                            <h5 class="pdl30">1) 서비스 이용요금</h5>
                            <h6 class="pdl60">- LAN 방식 <span>※ 표 안의 금액은 부가세가 포함된 실제 지불 금액입니다. (원/월)</span></h6>
                            <table class="tbl_type03">
                                <colgroup>
                                    <col style="width: 325px">
                                    <col style="width: 325px">
                                    <col style="">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th rowspan="2" colspan="2">구분 (제공속도)</th>
                                        <th>제공속도</th>
                                    </tr>
                                    <tr>
                                        <th>56K/64K/128K/256K/512K/1M/2M/5M</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="2" class="bgGray">business</td>
                                        <td>일반</td>
                                        <td rowspan="4">요금 견적 별도 문의<br />(1588-0114)<br />※ 약관가는 브로슈어 참조</td>
                                    </tr>
                                    <tr>
                                        <td>Local Routing</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" class="bgGray">premium</td>
                                        <td>일반</td>
                                    </tr>
                                    <tr>
                                        <td>Local Routing</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mgt20">
                                ※ 위 표에 명시된 접속 속도 이외 속도의 요금은 협정을 따릅니다.<br />
                                ※ Local Routing : 같은 지역(KT 망 기준으로 동일한 노드) 내에서 고객이 여러 개의 회선을 이용하는 경우에 제공되는 접속 방식입니다.<br />
                                ※ Local Routing 적용 조건 : 3회선 이상 가입 시 Local Routing 서비스에 가입할 수 있습니다. 단, 2번째 회선까지는 일반 요금이 부과되고, <br /><span class="pdl20">3번째 회선부터 Local Routing 요금이 부과됩니다.</span>
                            </p>


                            <h5 class="pdl30 mgt40">2) 설치비/이전비</h5>
                            <table class="tbl_type03">
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>신규 및 이전</th>
                                        <th>구내이전</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="3" class="noList">
                                            요금 견적은 로그인 후 확인<br>※ 약관가는 브로슈어 참조
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <h5 class="pdl30 mgt40">3) 할인율</h5>
                            <p class="mgt20">
                                ※회선별 할인, 장기계약 할인 등 다양한 할인 프로그램 제공
                            </p>
                        </div>

                        <div class="con02">
                            <h4>2. X4Biz Plus</h4>
                            <h5 class="pdl30">1) 서비스 이용요금</h5>
                            <h6 class="pdl60">- LAN 방식 <span>※ 표 안의 금액은 부가세가 포함된 실제 지불 금액입니다. (원/월)</span></h6>
                            <table class="tbl_type03">
                                <colgroup>
                                    <col style="width: 232px">
                                    <col style="">
                                    <col style="">
                                    <col style="">
                                    <col style="">
                                    <col style="">
                                    <col style="">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th rowspan="2">구분 (제공속도)</th>
                                        <th colspan="3">premium 서비스</th>
                                        <th colspan="3">business 서비스</th>
                                    </tr>
                                    <tr>
                                        <th>1 이용자</th>
                                        <th>2 이용자</th>
                                        <th>3 이용자</th>
                                        <th>1 이용자</th>
                                        <th>2 이용자</th>
                                        <th>3 이용자</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="7" class="noList">
                                            요금 견적은 로그인 후 확인<br>※ 약관가는 브로슈어 참조
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mgt20">
                                ※ 4Biz서비스는 이용자수에 따라 요금이 책정되며, 이용기간 및 회선 수에 따라 할인율이 적용됩니다.<br />
                                ※ Premium서비스는 인터넷 접속까지 가능하며 business서비스는 인터넷 접속이 되지 않는 인트라넷용입니다.<br />
                            </p>


                            <h5 class="pdl30 mgt40">2) 설치비/이전비 <span>※ 표 안의 금액은 부가세가 포함된 실제 지불 금액입니다. (원/월)</span></h5>
                            <table class="tbl_type03">
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>신규 및 이전</th>
                                        <th>구내이전</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="3" class="noList">
                                            요금 견적은 로그인 후 확인<br>※ 약관가는 브로슈어 참조
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <h5 class="pdl30 mgt40">3) 할인율</h5>
                            <p class="mgt20">
                                ※회선별 할인, 장기계약 할인 등 다양한 할인 프로그램 제공
                            </p>
                        </div>

                        <div class="con03">
                            <h4>3. X4Biz</h4>
                            <h5 class="pdl30">1) 서비스 이용요금<span>※ 표 안의 금액은 부가세가 포함된 실제 지불 금액입니다. (원/월)</span></h5>
                            <table class="tbl_type03">
                                <thead>
                                    <tr>
                                        <th>User수</th>
                                        <th>Standard</th>
                                        <th>Dual</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1 User</td>
                                        <td><span class="fcPurple3 fw600">143,000</span>원</td>
                                        <td><span class="fcPurple3 fw600">198,000</span>원</td>
                                    </tr>
                                    <tr>
                                        <td>10 User</td>
                                        <td><span class="fcPurple3 fw600">198,000</span>원</td>
                                        <td><span class="fcPurple3 fw600">297,000</span>원</td>
                                    </tr>
                                    <tr>
                                        <td>20 User</td>
                                        <td><span class="fcPurple3 fw600">275,000</span>원</td>
                                        <td><span class="fcPurple3 fw600">396,000</span>원</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mgt20">
                                ※ X4Biz서비스는 이용자수에 따라 요금이 책정되며, 이용기간 및 회선 수에 따라 할인율이 적용됩니다.
                            </p>


                            <h5 class="pdl30 mgt40">2) 설치비/이전비 <span>※ 표 안의 금액은 부가세가 포함된 실제 지불 금액입니다. (원/월)</span></h5>
                            <table class="tbl_type03">
                                <thead>
                                    <tr>
                                        <th>User수</th>
                                        <th>Standard</th>
                                        <th>Dual</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>신규 및 이전</td>
                                        <td><span class="fcPurple3 fw600">55,000</span>원</td>
                                        <td><span class="fcPurple3 fw600">88,000</span>원</td>
                                    </tr>
                                    <tr>
                                        <td>구내 이전</td>
                                        <td><span class="fcPurple3 fw600">27,500</span>원</td>
                                        <td><span class="fcPurple3 fw600">44,000</span>원</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mgt20">
                                ※ X4Biz서비스는 이용자수에 따라 요금이 책정되며, 이용기간 및 회선 수에 따라 할인율이 적용됩니다.
                            </p>

                            <h5 class="pdl30 mgt40">3) 할인율</h5>
                            <p class="mgt20">
                                ※ 회선별 할인, 장기계약 할인 등 다양한 할인 프로그램 제공<br />
                                ※ X4Biz Dual은 다량회선 할인 시 2회선으로 신청합니다.
                            </p>
                        </div>
                    </div>

                    <div id="section-03" class="vpn-section-03">
                        <h3 class="sCtitle">이용안내</h3>
                        <h4>꼭 알아두세요!</h4>
                        <div class="dotList indent">
                            <ul>
                                <li>서비스 신청에서 개통까지 3~5일정도 소요되며, X4Biz 상품은 초고속(ADSL)회선 개통 후, 2~3일정도 소요됩니다.</li>
                                <li>설치환경에 따라 개통일정은 변경될 수 있습니다.</li>
                            </ul>
                        </div>
                        <h4>설치 및 이용 가이드</h4>
                        <div class="dotList indent">
                            <ul>
                                <li>
                                    UTM 또는 라우터에 공유기 설치 방법구내회선은 고객님께서 직접 구성해 사용하셔야 됩니다.
                                    <span class="pdl40 fc111 ln18 mgt20">
                                        1. 모뎀의 LAN포트와 전화기 LAN포트에 인터넷 선을 연결합니다.<br />
                                        2. 공유기 사용 고객은 공유기 LAN포트와 전화기 LAN포트를 연결합니다.<br />
                                        3. 컴퓨터와 연결된 선을 PC포트에 연결합니다.<br />
                                        4. 전원 어댑터를 연결합니다.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <h4>신청 및 문의</h4>
                        <div class="dotList indent">
                            <ul>
                                <li>전화상담신청(서비스 문의, 신청 및 해지) : 1588-0114</li>
                                <li>고장(장애)접수 : 1588-0114, 080-789-0099(KREN)</li>
                                <li>1:1 문의하기</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'
import common from '@/assets/js/common.js'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
        active: 1,
				sectionNum: [
					'section-01',
					'section-02',
					'section-03'
				],
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
		updateNum(num) {
			this.active = num
		},
    moveTab(num) {
      var location = common.getOffset(document.getElementById(this.sectionNum[num-1])).top - 30
      window.scrollTo({top:location, behavior:'smooth'});
    }
  }
}
</script>